import { fork, call, select, put } from 'redux-saga/effects';
import { LoadType, LoadOptions } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { IMadadAgentsOfficesVariables } from 'utils/entities';
import { State as RouteState, Route } from 'config/routes';
import { filterRecordsByDocIdSelector, madad2024CityDocIdSelector, madadCityDocIdSelector } from 'store/state/selectors/madad';
import { createSelector } from 'reselect';
import { flow } from 'lodash';
import { getRouteParams } from 'store/state/selectors/router';
import { navigateTo } from 'store/state/router/actions';
import { ARCHIVE_MADAD_YEAR_2021, ARCHIVE_MADAD_YEAR_2022, ARCHIVE_MADAD_YEAR_2023 } from 'screens/MadadArchivePage/constants';


function* loadMadadArchiveData() {
  const loadOptions: LoadOptions<LoadType.Madad> = {
    loadType: LoadType.Madad,
    meta: {
      variables: {},
    },
  };
  yield fork(queryData, loadOptions);
}

export function* madadPageArchiveHandler(route: RouteState) {
  const { year } = route.params;

  switch (year) {
    case ARCHIVE_MADAD_YEAR_2021:
    case ARCHIVE_MADAD_YEAR_2022:
    case ARCHIVE_MADAD_YEAR_2023:
      yield call(loadMadadData, year);
      return;

    default:
  }

  yield put(navigateTo(Route.Home, {}, { replace: true, ssrHttpStatus: 302 }));
}

export const shouldRedirectSelector = createSelector([
  flow(getRouteParams, p => p.id),
  filterRecordsByDocIdSelector,
], (docId, dict) => docId && dict[docId] === undefined);

export function* loadMadadData(year?: number, agentId?: string) {
  const loadOptions: LoadOptions<LoadType.MadadParticipants> = {
    loadType: LoadType.MadadParticipants,
    meta: {
      variables: { year },
    },
  };
  yield call(queryData, loadOptions);
  const cityDocId: string = yield select(year ? madadCityDocIdSelector : madad2024CityDocIdSelector);
  const shouldRedirect: boolean = yield select(shouldRedirectSelector);

  if (shouldRedirect) {
    yield put(navigateTo(Route.MadadPage, {}, { replace: true, ssrHttpStatus: 302 }));
  }

  if (cityDocId) {
    yield fork(loadMadadWinners, { docIds: [ cityDocId ], onlyWinners: true, year });
    const bannerLoadOpts: LoadOptions<LoadType.MadadAgentBanner> = {
      loadType: LoadType.MadadAgentBanner,
      meta: {
        variables: {
          docId: cityDocId,
          ...(agentId ? { agentId } : null),
        },
      },
    };

    if (!year) {
      yield fork(queryData, bannerLoadOpts);
    }
  }
}

function* loadMadadWinners(payload: IMadadAgentsOfficesVariables) {
  const { docIds, onlyWinners, year } = payload;

  const loadOptions: LoadOptions<LoadType.MadadAgentsOffices> = {
    loadType: LoadType.MadadAgentsOffices,
    meta: {
      variables: {
        docIds,
        onlyWinners,
        year,
      },
    },
  };
  yield fork(queryData, loadOptions);
}

export function* madadPageHandler(route: RouteState) {
  const { agentId } = route.params;
  yield call(loadMadadData, undefined, agentId);
}

export function *madadPageArchive2020Handler() {
  yield call(loadMadadArchiveData);
}
